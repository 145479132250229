import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Insights = () => {
    const options1 = {
        type: 'column',
        title: '',
        categories: ['1', '2', '3', '4', '5'],
        colorByPoint: true,
        colors: ['#7A0200', '#7A0200', '#00164e', '#0b7222', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [
          {
            data: [2, 7, 14, 47, 28],
            dataLabels: {
              enabled: true,
              format: '{y}%',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          },
        ]
      };
    
      const options2 = {
        type: 'column',
        title: '',
        categories: ['1', '2', '3', '4', '5'],
        colorByPoint: true,
        colors: ['#7A0200', '#7A0200', '#00164e', '#0b7222', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [
          {
            data: [4, 6, 14, 42, 34],
            dataLabels: {
              enabled: true,
              format: '{y}%',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          },
        ]
      };
      const options3 = {
        type: 'column',
        title: '',
        categories: ['Very <br/> Manageable', 'Manageable', 'Somewhat <br/> Manageable', 'Challenging', 'Very <br/> Challenging'],
        colorByPoint: true,
        colors: ['#0b7222', '#0b7222', '#00164e', '#74BBC9', '#74BBC9'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [
          {
            data: [4, 6, 14, 42, 34],
            dataLabels: {
              enabled: true,
              format: '{y}%',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          },
        ]
      };
    
    
      const getOptions = (props) => ({
        chart: {
          type: props.type,
          /*width: props.width===undefined ? 500 : props.width,
          height: props.height===undefined ? 400 : props.height,*/
          backgroundColor: 'white',
        },
        plotOptions: {
          column: {
            colorByPoint: props.colorByPoint
          },
          series: {
            borderRadius: '25%'
          }
        },
        colors: props.colors,
        title: {
          text: props.title
        },
        credits: {
          enabled: props.creditsEnabled
        },
        legend: {
          enabled: props.legendEnabled
        },
        yAxis: {
          title: {
            text: props.yAxisTitle !== undefined ? props.yAxisTitle : 'Percentages(%)',
          },
        },
        xAxis: {
          categories: props.categories,
          labels: {
            style: {
              fontSize: '10px', // Adjust font size if needed
              whiteSpace: 'normal', // Allow labels to wrap when long
              overflow: 'justify',
            },
            tickWidth: 0,
          },
        },
        series: props.series,
      });
    
    
    return (
        <>
           <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >How satisfied are you with your work-week overall?</span>
                      <p className='font-style-italic'>(1 = Very Dissatisfied, 5 = Very Satisfied)</p>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options1)} />
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >Rate the level of collaboration and communication within team over the past week?</span>
                      <p className='font-style-italic'>(1 = Very Dissatisfied, 5 = Very Satisfied)</p>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options2)} />
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >Do you feel that your workload is manageable and realistic?</span>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options3)} />
                  </div>
                </div>
              </div>
            </div>
        </>
    )
}
export default Insights;


