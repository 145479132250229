import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Respondents = () => {

      const options1 = {
        type: 'column',
        title: '',
        categories: ["Top <br/>Management", "Senior <br/> Management", "Professional /<br/> Middle Management", "Technical / <br/>Junior Management", "Semi-Skilled","Unskilled"],
        colorByPoint: false,
        colors: ['#00164e', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
          {
            name: 'Headcount',
            data: [12,150,1474,2374,24,5],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#00164e',
          },
          {
            name: 'Respondents',
            data: [3,98,850,2108,24,3],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          }
        ]
      };
      const options2 = {
        type: 'column',
        title: '',
        categories: ["Male ","Female"],
        colorByPoint: false,
        colors: ['#00164e', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
          {
            name: 'Headcount',
            data: [2352,1635],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#00164e',
          },
          {
            name: 'Respondents',
            data: [1944,1022],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          }
        ]
      };
      const options3 = {
        type: 'column',
        title: '',
        categories: ["Male ","Female"],
        colorByPoint: false,
        colors: ['#00164e', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
          {
            name: 'Headcount',
            data: [1878,635,729,787],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#00164e',
          },
          {
            name: 'Respondents',
            data: [1200,592,640,459],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          }
        ]
      };

      const options4 = {
        type: 'column',
        title: '',
        categories: ["00 - 02 ","03 - 05","06 - 10","11 - 20","21 - 30","31+"],
        colorByPoint: false,
        colors: ['#00164e', '#0b7222'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
          {
            name: 'Headcount',
            data: [854,768,1209,1098,924,74],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#00164e',
          },
          {
            name: 'Respondents',
            data: [797,592,820,459,643,48],
            dataLabels: {
              enabled: true,
              format: '{y}',
              style: {
                color: 'black',
                textOutline: 'none'
              }
            },
            color: '#5BBDCB',
          }
        ]
      };
    
    
      const getOptions = (props) => ({
        chart: {
          type: props.type,
          /*width: props.width===undefined ? 500 : props.width,
          height: props.height===undefined ? 400 : props.height,*/
          backgroundColor: 'white',
        },
        plotOptions: {
          column: {
            colorByPoint: props.colorByPoint
          },
          series: {
            borderRadius: '25%'
          }
        },
        colors: props.colors,
        title: {
          text: props.title
        },
        credits: {
          enabled: props.creditsEnabled
        },
        legend: {
          enabled: props.legendEnabled
        },
        yAxis: {
          title: {
            text: props.yAxisTitle !== undefined ? props.yAxisTitle : 'Percentages(%)',
          },
        },
        xAxis: {
          categories: props.categories,
          labels: {
            style: {
              fontSize: '10px', // Adjust font size if needed
              whiteSpace: 'normal', // Allow labels to wrap when long
              overflow: 'justify',
            },
            tickWidth: 0,
          },
        },
        series: props.series,
      });
    
    
    return (
        <>
           <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >Employee Level</span>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options1)} />
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >Gender</span>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options2)} />
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span>Race</span>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options3)} />
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className="mx-3 my-3">
                      <span >Tenure</span>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={getOptions(options4)} />
                  </div>
                </div>
              </div>

            </div>

        </>
    )
}
export default Respondents;


