import React, { useEffect, useState } from 'react';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Insights from './Insights';
import Verbatim from './Verbatim';
import WordCloud from './WordCloud';
import Respondents from './Respondents.jsx';

const AnnualSurveysResults = () => {
  const [active, setActive] = useState(1);


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Survey Results / Annual Surveys"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='d-flex'>
            <button className="btn btn-primary btn-sm mx-2 py-1">RESET</button>
            <button className="btn btn-primary btn-sm py-1 ">REAPPLY</button>
          </div>
          <TempFilters />
          <div className='d-flex flex-row'>
            <p className='Select Survey pt-2'>Select Survey:</p>
            <p className='m-1'>
              <select class="form-select survey-select ms-2 py-1 w-25">
               
              </select>
            </p>
          </div>
          <br />
        </section>
        <section className="section ">
          <div className='row '>
            <div className='col-lg-12 col-md-12 col-sm-12 mb-5'>
              {["Respondents", "Insights", "Verbatim", "Word Cloud"].map((item, key) => (
                <>
                  <button onClick={() => setActive(key + 1)} className={`btn border bg-light    ${key + 1 == active ? "bg-secondary text-light" : "bg-light text-primary"} fs-4 fw-bold px-4 py-2 me-5`}>
                    {item}
                  </button>
                </>
              ))}
            </div>
            
            <div className='row'>
              <div className='col-lg-12 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='fw-bold text-grey'>You have not yet generated a survey</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default AnnualSurveysResults;
