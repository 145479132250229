import React from 'react';

const WordCloud = ({toggle}) => {
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-5 col-md-8 col-sm-12 mx-auto mt-4'>
                  {toggle ? <img className='fluid-image rounded ' src='assets/images/pulse_survey_wordcloud_1.png' />:<img className='fluid-image rounded ' src='assets/images/pulse_survey_wordcloud_2.png'/>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default WordCloud;


