import React, { useState } from 'react';


const Verbatim = () => {
  const [data, setData] = useState({
    question: "Are there any specific challenges or concerns you believe the organization should address to improve employee well-being and satisfaction?",
    answers: [
      "I think the organisation should focus on providing more opportunities for skill development and career advancement.",
      "There’s a need for better work-life balance initiatives, such as flexible scheduling or remote work options.",
      "Communication between departments needs improvement; it can be a barrier to collaboration and efficiency.",
      "Recognition and appreciation for hard work should be more frequent to boost morale.",
      "We need better mental health support programs for employees facing stress and burnout.",
      "Training programs are outdated; we should invest in more relevant and continuous learning opportunities.",
      "There’s a lack of clarity around performance expectations and career growth paths.",
      "Teamwork is essential, but we need to address issues related to team dynamics and conflicts.",
      "We should consider increasing the frequency of performance feedback and evaluations.",
      "Benefits and compensation packages need to be reviewed to stay competitive in the market.",
      "The organisation should prioritise diversity and inclusion efforts to create a more inclusive workplace.",
      "Health and safety measures need to be improved, especially for those working remotely"
    ]
  })
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div className='card-body'>
              <table className="table table-bordered">
                <thead>
                  <tr className='bg-primary text-light'>
                    <th scope="col">{data.question}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.answers.map((item, key) => (
                    <>
                      <tr key={key}>
                        <td> "{item}" </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default Verbatim;


