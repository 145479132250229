import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const PulseSurvey = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys / Pulse Surveys"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='font-weight-600 my-2'>End of year Pulse-check</h5>
                  <br />
                  <div className='row'>
                    <div className='col-lg-10 col-md-12 col-sm-12'>
                      <div>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>On a scale of 1 to 5, how engaged do you currently feel in your role?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Not Engaged, 5 = Highly Engaged)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 2} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How satisfied are you with the level of communication within the organisation?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Very Dissatisfied, 5 = Very Satisfied)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 2} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>Do you believe your career development needs are being adequately addressed by the organisation?</div>
                        <div className='d-flex ms-3 mt-3'>
                          {["Strongly Agree", "Agree", "Neutral", "Disagree", "Strongly Disagree"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 3} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>


                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How well do you think your team collaborates on projects and tasks?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Poor, 5 = Excellent)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 2} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>Do you believe your career development needs are being adequately addressed by the organisation?</div>
                        <div className='d-flex ms-3 mt-3'>
                          {["Very Manageable", "Manageable", "Somewhat Manageable", "Challenging", "Very Challenging"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 3} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>


                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How would you rate the overall workplace morale and atmosphere in your department?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Poor, 5 = Excellent)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 2} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>


                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>Are there any specific challenges or concerns you believe the organization should address to improve
                          employee well-being and satisfaction?</div>
                        <div className='d-flex my-4'>
                          <textarea className='form-group border rounded-2 w-100'>
                          </textarea>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <button className="btn btn-secondary btn-sm mx-2 ms-auto">SAVE</button>
                        <button className="btn btn-primary btn-sm  ">SUBMIT</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default PulseSurvey;
