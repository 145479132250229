import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Home = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header label={<h5 className='py-0 my-0 text-dark font-style-italic'>Your Feedback, Our Commitment!</h5>} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <p>
                  To strengthen the culture that we wish to build for our people and ensure we create a work environment that enables all employees to achieve
                  their full potential, we need to create opportunities for employees to provide us with open and honest reflection and feedback.
                </p>
                <p>
                  Rest assured that the information you share will be managed in a responsible way without compromising our values of respecting each other
                  and upholding the highest levels of integrity.
                </p>
                <div className='mt-5'>
                 <div className='row'>
                  <div className='col-lg-10 col-md-10 col-sm-12 mx-auto'>
                  <img className='fluid-image rounded mx-auto' src='assets/images/Picture1.png' />
                  </div>
                 </div>

                </div>
              </div>
            </div>
          </div>
        </div>




        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  {[{ name: "You have not started your survey", color: "text-warning" }, { name: "You have started, but not yet submitted your survey", color: "text-secondary" }, { name: "You have completed your survey", color: "text-success" }, { name: "There is currently no survey available", color: "text-grey" }].map((item, key) => (
                    <>
                      <div key={key} className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='my-3'>
                          <i class={`fa-solid fa-bell ${item.color} bell-size`}></i>
                        </div>
                        <div>
                          {item.name}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {[
            { page: "Always-on Surveys", description: "Your weekly check-in", data: [{ name: "09 Oct ’23 : Check-in", status: "Not started", days: 3 }], color: "text-warning" },
            { page: "Spot Surveys", description: "Your feedback on change!", data: [{ name: "New Flexible Work Policy", status: "Complete", days: 9 }], color: "text-success" },
            { page: "Pulse Surveys", description: "Your weekly check-in: 09 October", data: [{ name: "End of year Pulse-check", status: " In Progress", days: 11 }], color: "text-secondary" },
            { page: "Annual Surveys", description: "Your input for our baseline", data: [], color: "text-grey" },
          ].map((item, key) => (
            <>
              <div key={key} className='col-lg-6 col-md-6 col-sm-12'>
                <div className='card'>
                  <div className='card-body p-4'>
                    <div className='my-3 d-flex'>
                      <div className='me-2'>
                        <i class={`fa-solid fa-bell ${item.color} bell-size`}></i>
                      </div>
                      <div className='flex-grow-1 d-flex flex-column'>
                        <p className='fw-bold my-0'>{item.page}</p>
                        <p className=''>{item.description}</p>
                      </div>
                    </div>
                    <div>
                      {item.data.map((element, key) => (
                        <>
                          <div className='bg-primary fw-bold text-light p-1 rounded-1'>{element.name}</div>
                          <div className='my-3'>Status: <span className={`fw-bold ${item.color}`}>{element.status}</span></div>
                          <div className='font-style-italic'><i class="fa-solid fa-circle-info fa-xl text-primary me-3"></i>Closes in <span className={`fw-bold`}>{element.days}</span> days!</div>
                        </>
                      ))}

                      {item.data.length == 0 && (
                        <>
                          <div className='bg-grey-100 fw-bold text-light p-1 rounded-1 border b'>No active survey available </div>
                          <br />
                          <br />
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>




      </main>

    </>
  )
}
export default Home;
