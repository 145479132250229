import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const SpotSurveys = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys / Spot Surveys"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='font-weight-600 my-2'>New Flexible Work Policy</h5>
                  <br />
                  <div className='row'>
                    <div className='col-lg-10 col-md-12 col-sm-12'>
                      <div>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How satisfied are you with new flexible work hours policy that was recently implemented?</div>
                        <div className='d-flex ms-3 '>
                          {[
                            {rotate:"0", color:"dark-green"}, 
                            {rotate:"325", color:"light-green"},  
                            {rotate:"270", color:"text-yellow"}, 
                            {rotate:"225", color:"text-warning"}, 
                            {rotate:"180", color:"text-brown"}
                          ].map((item, key) => (
                            <>
                              <div className='my-3' key={key}>
                                <input defaultChecked={key == 3} type="radio" />
                                <i className={`ms-2 me-5 fa-solid fa-thumbs-up bell-size like-${item.rotate} ${item.color}`} ></i>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className='d-flex'>
                        <button className="btn btn-secondary btn-sm mx-2 ms-auto">SAVE</button>
                        <button className="btn btn-primary btn-sm  ">SUBMIT</button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default SpotSurveys;
