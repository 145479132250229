import React, { useState } from 'react';


const Verbatim = () => {
  const [data, setData] = useState({
    question: "Are there any specific challenges or concerns you believe the organization should address to improve employee well-being and satisfaction?",
    answers: [
      "I suggest implementing a more efficient task management system to streamline our workflow.",
      "t would be helpful to have regular team meetings for better coordination and communication.",
      "I recommend offering additional training opportunities to enhance our skills.",
      "Consider creating a dedicated space for quiet, focused work to minimize distractions.",
      "We should explore flexible scheduling options to accommodate different working preferences.",
      "Please improve the onboarding process for new employees; it felt a bit disorganised.",
      "It would be great to have healthier snack options in the break room.",
      "I suggest conducting a monthly feedback session with supervisors to address concerns.",
      "Let’s invest in better ergonomic equipment to improve comfort and productivity.",
      "Consider implementing a recognition program to acknowledge outstanding contributions."
    ]
  })
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div className='card-body'>
              <table className="table table-bordered">
                <thead>
                  <tr className='bg-primary text-light'>
                    <th scope="col">{data.question}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.answers.map((item, key) => (
                    <>
                      <tr key={key}>
                        <td> "{item}" </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default Verbatim;


