import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const AlwaysOnSurveys = () => {
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys / Always-on Surveys"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='font-weight-600 my-2'>09 Oct ’23 : Check-in</h5>
                  <br />
                  <div className='row'>
                    <div className='col-lg-10 col-md-12 col-sm-12'>
                      <div>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>On a scale of 1 to 10, how satisfied are you with your work-week overall?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Very Dissatisfied, 10 = Very Satisfied)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5","6","7","8","9","10"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 5} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How would you rate the level of collaboration and communication within your team over the past week?</div>
                        <p className='my-3 ms-3 font-style-italic'>(1 = Very Dissatisfied, 10 = Very Satisfied)</p>
                        <div className='d-flex ms-3'>
                          {["1", "2", "3", "4", "5","6","7","8","9","10"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 6} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>Do you feel that your workload was manageable this week, or did you feel overwhelmed?</div>
                        <div className='d-flex ms-3 mt-3'>
                          {["Very Overwhelmed", "Overwhelmed", "Somewhat Overwhelmed", "Manageable", "Very Manageable"].map((item, key) => (
                            <>
                              <div key={key}>
                                <input defaultChecked={key == 3} type="radio" />
                                <span className='ms-2 me-5'>{item}</span>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className='py-4'>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>Are there any specific challenges or concerns you believe the organization should address to improve employee well-being and satisfaction?</div>
                        <div className='d-flex my-4'>
                          <textarea className='form-group border rounded-2 w-100'>
                          </textarea>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <button className="btn btn-secondary btn-sm mx-2 ms-auto">SAVE</button>
                        <button className="btn btn-primary btn-sm  ">SUBMIT</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default AlwaysOnSurveys;
