import React, { useEffect, useState } from 'react';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';

const SpotSurveysResults = () => {

  const data = [
    {
      category: "African",
      stronglyAgreeCount: 1,
      stronglyAgreePercentage: 8.3,

      agreeCount: 33,
      agreePercentage: 22.0,

      neutralCount: 571,
      neutralPercentage: 38.7,

      disagreeCount: 1779,
      disagreePercentage: 61.6,

      stronglyDisagreeCount: 17,
      stronglyDisagreePercentage: 70.8,
    },

    {
      category: "Indian",
      stronglyAgreeCount: 3,
      stronglyAgreePercentage: 25.0,

      agreeCount: 34,
      agreePercentage: 22.7,

      neutralCount: 309,
      neutralPercentage: 21.0,

      disagreeCount: 288,
      disagreePercentage: 10.0,

      stronglyDisagreeCount: 1,
      stronglyDisagreePercentage: 4.2,
    },

    {
      category: "Coloured",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 13,
      agreePercentage: 8.7,

      neutralCount: 180,
      neutralPercentage: 12.2,

      disagreeCount: 530,
      disagreePercentage: 8.3,

      stronglyDisagreeCount: 3,
      stronglyDisagreePercentage: 12.5,
    },

    {
      category: "White",
      stronglyAgreeCount: 8,
      stronglyAgreePercentage: 66.7,

      agreeCount: 70,
      agreePercentage: 46.7,

      neutralCount: 414,
      neutralPercentage: 28.1,

      disagreeCount: 292,
      disagreePercentage: 10.1,

      stronglyDisagreeCount: 3,
      stronglyDisagreePercentage: 12.5,
    },
    {
      empty: true,
      category: "White",
      stronglyAgreeCount: 8,
      stronglyAgreePercentage: 66.7,

      agreeCount: 70,
      agreePercentage: 46.7,

      neutralCount: 414,
      neutralPercentage: 28.1,

      disagreeCount: 292,
      disagreePercentage: 10.1,

      stronglyDisagreeCount: 3,
      stronglyDisagreePercentage: 12.5,
    },

    {
      category: "Female",
      stronglyAgreeCount: 1,
      stronglyAgreePercentage: 8.3,

      agreeCount: 31,
      agreePercentage: 39.3,

      neutralCount: 745,
      neutralPercentage: 50.5,

      disagreeCount: 1938,
      disagreePercentage: 67.1,

      stronglyDisagreeCount: 14,
      stronglyDisagreePercentage: 58.3,
    },

    {
      category: "Male",
      stronglyAgreeCount: 11,
      stronglyAgreePercentage: 91.7,

      agreeCount: 48,
      agreePercentage: 60.7,

      neutralCount: 729,
      neutralPercentage: 49.5,

      disagreeCount: 951,
      disagreePercentage: 32.9,

      stronglyDisagreeCount: 10,
      stronglyDisagreePercentage: 41.7,
    },
    {
      empty: true,
      category: "Male",
      stronglyAgreeCount: 11,
      stronglyAgreePercentage: 91.7,

      agreeCount: 48,
      agreePercentage: 60.7,

      neutralCount: 729,
      neutralPercentage: 49.5,

      disagreeCount: 951,
      disagreePercentage: 32.9,

      stronglyDisagreeCount: 10,
      stronglyDisagreePercentage: 41.7,
    },

    {
      category: "00 - 02",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 19,
      agreePercentage: 12.7,

      neutralCount: 247,
      neutralPercentage: 16.8,

      disagreeCount: 687,
      disagreePercentage: 23.8,

      stronglyDisagreeCount: 1,
      stronglyDisagreePercentage: 4.2,
    },

    {
      category: "03 - 05",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 20,
      agreePercentage: 13.3,

      neutralCount: 216,
      neutralPercentage: 14.7,

      disagreeCount: 530,
      disagreePercentage: 18.3,

      stronglyDisagreeCount: 2,
      stronglyDisagreePercentage: 8.3,
    },

    {
      category: "06 - 10",
      stronglyAgreeCount: 3,
      stronglyAgreePercentage: 25.0,

      agreeCount: 45,
      agreePercentage: 30.0,

      neutralCount: 350,
      neutralPercentage: 23.7,

      disagreeCount: 646,
      disagreePercentage: 22.4,

      stronglyDisagreeCount: 6,
      stronglyDisagreePercentage: 25.0,
    },

    {
      category: "11 - 20",
      stronglyAgreeCount: 6,
      stronglyAgreePercentage: 50.0,

      agreeCount: 40,
      agreePercentage: 26.7,

      neutralCount: 386,
      neutralPercentage: 26.2,

      disagreeCount: 731,
      disagreePercentage: 25.3,

      stronglyDisagreeCount: 11,
      stronglyDisagreePercentage: 45.8,
    },

    {
      category: "21 - 30",
      stronglyAgreeCount: 3,
      stronglyAgreePercentage: 25.0,

      agreeCount: 15,
      agreePercentage: 10.0,

      neutralCount: 176,
      neutralPercentage: 11.9,

      disagreeCount: 207,
      disagreePercentage: 7.2,

      stronglyDisagreeCount: 3,
      stronglyDisagreePercentage: 12.5,
    },

    {
      category: "31 +",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 11,
      agreePercentage: 7.3,

      neutralCount: 99,
      neutralPercentage: 6.7,

      disagreeCount: 88,
      disagreePercentage: 3.0,

      stronglyDisagreeCount: 1,
      stronglyDisagreePercentage: 4.2,
    },
    {
      empty: true,
      category: "31 +",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 11,
      agreePercentage: 7.3,

      neutralCount: 99,
      neutralPercentage: 6.7,

      disagreeCount: 88,
      disagreePercentage: 3.0,

      stronglyDisagreeCount: 1,
      stronglyDisagreePercentage: 4.2,
    },

    {
      category: "18 - 24",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 20,
      agreePercentage: 0.0,

      neutralCount: 0,
      neutralPercentage: 0.0,

      disagreeCount: 92,
      disagreePercentage: 3.2,

      stronglyDisagreeCount: 0,
      stronglyDisagreePercentage: 0.0,
    },

    {
      category: "25 - 29",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 23,
      agreePercentage: 0.0,

      neutralCount: 32,
      neutralPercentage: 2.2,

      disagreeCount: 494,
      disagreePercentage: 17.1,

      stronglyDisagreeCount: 2,
      stronglyDisagreePercentage: 8.3,
    },

    {
      category: "30 - 39",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 31,
      agreePercentage: 11.3,

      neutralCount: 458,
      neutralPercentage: 31.1,

      disagreeCount: 1157,
      disagreePercentage: 40.0,

      stronglyDisagreeCount: 5,
      stronglyDisagreePercentage: 20.8,
    },

    {
      category: "40 - 49",
      stronglyAgreeCount: 5,
      stronglyAgreePercentage: 41.7,

      agreeCount: 48,
      agreePercentage: 51.3,

      neutralCount: 561,
      neutralPercentage: 38.1,

      disagreeCount: 643,
      disagreePercentage: 22.3,

      stronglyDisagreeCount: 12,
      stronglyDisagreePercentage: 50.0,
    },

    {
      category: "50 - 59",
      stronglyAgreeCount: 7,
      stronglyAgreePercentage: 58.3,

      agreeCount: 15,
      agreePercentage: 34.0,

      neutralCount: 391,
      neutralPercentage: 26.5,

      disagreeCount: 467,
      disagreePercentage: 16.2,

      stronglyDisagreeCount: 5,
      stronglyDisagreePercentage: 20.8,
    },

    {
      category: "60+",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 12,
      agreePercentage: 3.3,

      neutralCount: 32,
      neutralPercentage: 2.2,

      disagreeCount: 36,
      disagreePercentage: 1.2,

      stronglyDisagreeCount: 0,
      stronglyDisagreePercentage: 0.0,
    },
    {
      empty: true,
      category: "60+",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 12,
      agreePercentage: 3.3,

      neutralCount: 32,
      neutralPercentage: 2.2,

      disagreeCount: 36,
      disagreePercentage: 1.2,

      stronglyDisagreeCount: 0,
      stronglyDisagreePercentage: 0.0,
    },

    {
      category: "Top Management",
      stronglyAgreeCount: 9,
      stronglyAgreePercentage: 75.0,

      agreeCount: 2,
      agreePercentage: 16.7,

      neutralCount: 1,
      neutralPercentage: 8.3,

      disagreeCount: 0,
      disagreePercentage: 0.0,

      stronglyDisagreeCount: 0,
      stronglyDisagreePercentage: 0.0,
    },

    {
      category: "Senior Management",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 23,
      agreePercentage: 0.0,

      neutralCount: 32,
      neutralPercentage: 2.2,

      disagreeCount: 494,
      disagreePercentage: 17.1,

      stronglyDisagreeCount: 2,
      stronglyDisagreePercentage: 8.3,
    },

    {
      category: "Professional / Middle Management",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 31,
      agreePercentage: 11.3,

      neutralCount: 458,
      neutralPercentage: 31.1,

      disagreeCount: 1157,
      disagreePercentage: 40.0,

      stronglyDisagreeCount: 5,
      stronglyDisagreePercentage: 20.8,
    },

    {
      category: "Technical / Junior Management",
      stronglyAgreeCount: 5,
      stronglyAgreePercentage: 41.7,

      agreeCount: 48,
      agreePercentage: 51.3,

      neutralCount: 561,
      neutralPercentage: 38.1,

      disagreeCount: 643,
      disagreePercentage: 22.3,

      stronglyDisagreeCount: 12,
      stronglyDisagreePercentage: 50.0,
    },

    {
      category: "Semi - Skilled",
      stronglyAgreeCount: 7,
      stronglyAgreePercentage: 58.3,

      agreeCount: 15,
      agreePercentage: 34.0,

      neutralCount: 391,
      neutralPercentage: 26.5,

      disagreeCount: 467,
      disagreePercentage: 16.2,

      stronglyDisagreeCount: 5,
      stronglyDisagreePercentage: 20.8,
    },

    {
      category: "Unskilled",
      stronglyAgreeCount: 0,
      stronglyAgreePercentage: 0.0,

      agreeCount: 12,
      agreePercentage: 3.3,

      neutralCount: 32,
      neutralPercentage: 2.2,

      disagreeCount: 36,
      disagreePercentage: 1.2,

      stronglyDisagreeCount: 0,
      stronglyDisagreePercentage: 0.0,
    }
  ]
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Survey Results / Spot Surveys"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='d-flex'>
            <button className="btn btn-primary btn-sm mx-2 py-1">RESET</button>
            <button className="btn btn-primary btn-sm py-1 ">REAPPLY</button>
          </div>
          <TempFilters />
          <div className='d-flex flex-row'>
            <p className='Select Survey pt-2'>Select Survey:</p>
            <p className='m-1'>
              <select class="form-select ms-2 py-1 ">
                <option selected>New Flexible Work Policy</option>
              </select>
            </p>
          </div>
          <br />
        </section>
        <section className="section">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <br />
                  <div className='row'>
                    <div className='col-lg-10 col-md-12 col-sm-12'>
                      <div>
                        <div className='bg-tertiary text-light rounded-2 px-3 py-2'>How satisfied are you with new flexible work hours policy that was recently implemented?</div>
                        <div className='d-flex w-100'>
                        <div className='d-flex ms-3 mx-auto  ms-5 ps-5'>
                        <div className='ms-5 big-icon'></div>
                        <div className='ms-5 big-icon'></div>
                        <div className='ms-5 big-icon'></div>

                          {[
                            { rotate: "0", color: "dark-green",count:16 },
                            { rotate: "325", color: "light-green",count:32 },
                            { rotate: "270", color: "text-yellow",count:19 },
                            { rotate: "225", color: "text-warning",count:20 },
                            { rotate: "180", color: "text-brown",count:13 }
                          ].map((item, key) => (
                            <>
                            <div className='me-5 big-icon'>
                              <div>
                              <div className='my-3 d-flex' key={key}>
                                <i className={`ms-2 me-auto fa-solid fa-thumbs-up bell-size like-${item.rotate} ${item.color} big-icon`} ></i>
                              </div>
                              </div>
                              <div className='fw-bold text-center'>
                                {item.count}%
                              </div>
                            </div>
                            </>
                          ))}
                        </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='card'>
              <div className='card-header'>
                <table class=" survey-stats-table">
                  <thead>
                    <tr>
                      <th scope="col"></th>

                      {[
                        { rotate: "0", color: "dark-green" },
                        { rotate: "325", color: "light-green" },
                        { rotate: "270", color: "text-yellow" },
                        { rotate: "225", color: "text-warning" },
                        { rotate: "180", color: "text-brown" }
                      ].map((item, key) => (
                        <>
                          <th scope="col" className="text-center" colSpan={2} key={key}>
                            <i className={`w-100  me-5 fa-solid fa-thumbs-up bell-size like-${item.rotate} ${item.color}`} ></i>
                          </th>
                        </>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='row-empty'>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>#</td>
                      <td>%</td>
                      <td>#</td>
                      <td>%</td>
                      <td>#</td>
                      <td>%</td>
                      <td>#</td>
                      <td>%</td>
                      <td>#</td>
                      <td>%</td>
                    </tr>

                    {data.map((item, key) => (
                      <>
                        {
                          item.empty ? (<tr className='row-empty'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          ) : (
                            <tr>
                              <td>{item.category}</td>
                              <td>{item.stronglyAgreeCount}</td>
                              <td>{item.stronglyAgreePercentage}%</td>
                              <td>{item.agreeCount}</td>
                              <td>{item.agreePercentage}</td>
                              <td>{item.neutralCount}%</td>
                              <td>{item.neutralPercentage}%</td>
                              <td>{item.disagreeCount}</td>
                              <td>{item.disagreePercentage}%</td>
                              <td>{item.stronglyDisagreeCount}</td>
                              <td>{item.stronglyDisagreePercentage}%</td>
                            </tr>)
                        }
                      </>
                    ))}


                  </tbody >
                </table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default SpotSurveysResults;
