
import SignIn from './pages/SignIn';

import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';
import config from './config';
import IssueLogs from './pages/IssueLog/IssueLog';
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import Home from './pages/Home/Home';
import PulseSurvey from './pages/PulseSurvey/PulseSurvey';
import SpotSurveys from './pages/SpotSurveys/SpotSurveys';
import AlwaysOnSurveys from './pages/AlwaysOnSurveys/AlwaysOnSurveys';
import AlwaysOnSurveysResults from './pages/AlwaysOnSurveysResults/AlwaysOnSurveysResults';
import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX
import Highcharts from 'highcharts';
import PulseSurveysResults from './pages/PulseSurveysResults/PulseSurveysResults';
import AnnualSurveysResults from './pages/AnnualSurveysResults/AnnualSurveys';
import SpotSurveysResults from './pages/SpotSurveysResults/SpotSurveysResults';


// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState();
  const token = localStorage.getItem("token");
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = config.apiUrl;
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

  const getConversation = () => {
    axios.get("getConversations").then((res) => {
      setConversation(res.data.data)
    }).catch(() => {

    })
  }
  const createQuestion = () => {
    axios.post("createQuestion", { question }).then((res) => {
      getConversation();
      setQuestion("");
    }).catch(() => {

    })
  }


  return (
    <>

      {
        <button className="chatbot-toggler shadow ">
          <span className="material-symbols-rounded">
            <div className="bot-icon-container" onClick={() => { setShowChatbot((val) => { return !val }) }}>
              {showChatbot && <i class="fa-solid fa-xmark text-light"></i>}
              {!showChatbot && <i class="fa-solid fa-message text-light"></i>}
            </div>
            {showChatbot && <div className='position-absolute chatbot-package shadow rounded-4'>
              <Chatbot
                data={chatbotData}
                getConversation={getConversation}
                conversation={conversation}
                // onClose={()=>{ toggleBot(false)}}
                onChange={(value) => { setQuestion(value) }}
                onClose={() => { }}
                onSubmit={() => { createQuestion() }}
              />
            </div>
            }
          </span>
        </button>
      }
      <BrowserRouter>
        <Sidebar />
        <Routes >
          
          <Route exact path="/" element={<ProtectedRoutes element={<Home />} />} />
          <Route path="/pulse-surveys" element={<ProtectedRoutes element={< PulseSurvey />} />} />
          <Route path="/spot-surveys" element={<ProtectedRoutes element={< SpotSurveys />} />} />
          <Route path="/always-on-surveys" element={<ProtectedRoutes element={< AlwaysOnSurveys />} />} />
          <Route exact path="/always-on-surveys-results" element={<ProtectedRoutes element={<AlwaysOnSurveysResults />} />} />          
          <Route exact path="/pulse-surveys-results" element={<ProtectedRoutes element={<PulseSurveysResults />} />} />
          <Route exact path="/annual-surveys-results" element={<ProtectedRoutes element={<AnnualSurveysResults />} />} />
          <Route exact path="/spot-surveys-results" element={<ProtectedRoutes element={<SpotSurveysResults />} />} />


          

          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs />} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm />} />} />
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport />} />} />
        </Routes>
        <Routes>
          <Route path="/login" element={<Redirect element={< SignIn />} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
